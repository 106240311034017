import React from "react";
import { WrapPageElementNodeArgs } from "gatsby";

import { DefaultSeo } from "../components";

export const wrapPageElement = ({ element, props: { location } }: WrapPageElementNodeArgs) => {
  return (
    <>
      <DefaultSeo location={location} />
      {element}
    </>
  );
};
