import React, { FC } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Box, Text } from "@chakra-ui/react";

import { Link } from "../atoms";

export const Bio: FC = () => {
  const data = useStaticQuery<Queries.BioQuery>(graphql`
    query Bio {
      site {
        siteMetadata {
          author {
            name
          }
        }
      }
    }
  `);

  const author = data.site?.siteMetadata?.author;

  return (
    <Box>
      {author?.name && (
        <Text>
          Written by <Link to="/content/about">{author.name}</Link>
        </Text>
      )}
    </Box>
  );
};
