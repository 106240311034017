import { defineStyle, extendTheme, ThemeOverride } from "@chakra-ui/react";

const headingSizes = {
  "4xl": defineStyle({
    fontSize: "5xl",
    lineHeight: 1.1,
  }),
  "3xl": defineStyle({
    fontSize: "4xl",
    lineHeight: 1.3,
  }),
  "2xl": defineStyle({
    fontSize: "3xl",
    lineHeight: 1.5,
  }),
  xl: defineStyle({
    fontSize: "2xl",
    lineHeight: 1.7,
  }),
  lg: defineStyle({
    fontSize: "xl",
    lineHeight: 1.8,
  }),
  md: defineStyle({
    fontSize: "lg",
    lineHeight: 1.9,
  }),
  sm: defineStyle({
    fontSize: "md",
    lineHeight: 2,
  }),
  xs: defineStyle({
    fontSize: "sm",
    lineHeight: 2,
  }),
};

const theme: ThemeOverride = {
  borders: {
    default: "1px solid #dadada",
  },
  breakpoints: {
    sm: "30em",
    md: "48em",
    lg: "62em",
    xl: "80em",
    "2xl": "96em",
  },
  colors: {
    primary: {
      main: "#7cce50",
      50: "#fafcf5",
      100: "#f5faeb",
      200: "#e4f2ce",
      300: "#d1ebb2",
      400: "#a9de7e",
      500: "#7cce50",
      600: "#69ba41",
      700: "#4e9c2d",
      800: "#387d1d",
      900: "#235c11",
      950: "#133b06",
    },
  },
  config: {
    initialColorMode: "dark",
  },
  fonts: {
    heading: "'Saira Variable'",
    body: "'Saira Variable'",
  },
  sizes: {
    container: {
      sm: "full",
      md: "30em",
      lg: "48em",
      xl: "62em",
      "2xl": "80em",
    },
  },
  components: {
    Heading: {
      baseStyle: ({ as }) => {
        switch (as) {
          case "h1":
            return headingSizes["4xl"];
          case "h2":
            return headingSizes["3xl"];
          case "h3":
            return headingSizes["2xl"];
          case "h4":
            return headingSizes["xl"];
          case "h5":
            return headingSizes["lg"];
          case "h6":
            return headingSizes["md"];
          default:
            return headingSizes["3xl"];
        }
      },
      sizes: {
        dynamic: {},
      },
      defaultProps: {
        size: "dynamic",
      },
    },
    Link: {
      baseStyle: ({ colorScheme }) =>
        colorScheme
          ? {
              color: `${colorScheme}.500`,
              // textDecoration: "underline",
              _hover: {
                color: `${colorScheme}.400`,
              },
            }
          : {},
    },
  },
};

export default extendTheme(theme);
