import React, { FC } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { WindowLocation } from "@reach/router";
import { Helmet } from "react-helmet";
import { getSrc } from "gatsby-plugin-image";

type LinkProps = React.JSX.IntrinsicElements["link"];
type MetaProps = React.JSX.IntrinsicElements["meta"];

export type DefaultSeoProps = {
  location: WindowLocation;
};

export const DefaultSeo: FC<DefaultSeoProps> = ({ location }) => {
  const { site, ogImage } = useStaticQuery<Queries.DefaultSeoQuery>(
    graphql`
      query DefaultSeo {
        site {
          siteMetadata {
            siteUrl
          }
        }
        ogImage: file(absolutePath: { glob: "**/src/images/icon.png" }) {
          childImageSharp {
            gatsbyImageData(width: 300)
          }
        }
      }
    `
  );

  const url = `${site?.siteMetadata?.siteUrl}${location.pathname}`;
  const link: LinkProps[] = [
    {
      rel: "canonical",
      key: "canonical",
      href: url,
    },
  ];

  const meta: MetaProps[] = [
    {
      property: "og:url",
      key: "og:url",
      content: url,
    },
  ];
  if (ogImage?.childImageSharp?.gatsbyImageData) {
    meta.push({
      property: "og:image",
      key: "og:image",
      content: getSrc(ogImage?.childImageSharp?.gatsbyImageData) || "",
    });
  }

  return <Helmet link={link} meta={meta} />;
};
