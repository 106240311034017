import React, { FC } from "react";
import { Box, Flex, Icon, IconButton, LinkProps, List, ListItem, chakra, useColorMode, useColorModeValue } from "@chakra-ui/react";
import { MoonIcon, SunIcon } from "@chakra-ui/icons";

import { Link, ResponsiveContainer } from "../atoms";

export type NavigationProps = {};

const NavLink = chakra(Link, { baseStyle: { color: "inherit", textDecoration: "none", _hover: { color: "inherit" } } });

export const Navigation: FC<NavigationProps> = () => {
  const { colorMode, toggleColorMode } = useColorMode();

  const bg = useColorModeValue("gray.200", "gray.900");

  return (
    <Box borderBottom="2px" borderColor="primary.main" background={bg}>
      <ResponsiveContainer>
        <Flex as="nav" justify="space-between" align="center" paddingY={[2, 6]}>
          <List>
            <ListItem>
              <NavLink to="/">Lucas Loreggia</NavLink>
            </ListItem>
          </List>
          <List display="flex" alignItems="center" gap={4}>
            <ListItem>
              <Link to="/projects">Projects</Link>
            </ListItem>
            <ListItem>
              <Link to="/blog">Blog</Link>
            </ListItem>
            <ListItem>
              <Link to="/content/about">About</Link>
            </ListItem>
            <IconButton
              icon={<Icon as={colorMode === "light" ? MoonIcon : SunIcon} />}
              onClick={toggleColorMode}
              aria-label="Toggle color mode"
            ></IconButton>
          </List>
        </Flex>
      </ResponsiveContainer>
    </Box>
  );
};
