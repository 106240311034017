import React, { FC } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { ChakraProps, chakra } from "@chakra-ui/react";

import { ResponsiveContainer } from "../atoms";

export const Footer: FC<ChakraProps> = (props) => {
  const data = useStaticQuery<Queries.FooterQuery>(graphql`
    query Footer {
      site {
        siteMetadata {
          author {
            name
          }
        }
      }
    }
  `);

  const author = data.site?.siteMetadata?.author;
  return (
    <chakra.footer {...props}>
      <ResponsiveContainer textAlign="center" py={4}>
        <span>
          &copy; {author?.name}, {new Date().getFullYear()}
        </span>
      </ResponsiveContainer>
    </chakra.footer>
  );
};
