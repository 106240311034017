import React, { FC } from "react";
import { Link as GatsbyLink, GatsbyLinkProps } from "gatsby";
import { Link as ChakraLink, LinkProps as ChakraLinkProps } from "@chakra-ui/react";

export type LinkProps<TState> = GatsbyLinkProps<TState> & ChakraLinkProps;

export function Link<TState>(props: LinkProps<TState>) {
  const to = props.to || props.href || "/";
  const isInternal = /^\/(?!\/)/.test(to);
  if (isInternal) {
    return <ChakraLink as={GatsbyLink} {...props} to={to} />;
  } else {
    return <ChakraLink rel="noindex,nofollow" target="_blank" {...props} href={to} />;
  }
}
