import React, { FC } from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

type MetaProps = React.JSX.IntrinsicElements["meta"];

export type SeoProps = {
  description?: string;
  lang?: string;
  meta?: MetaProps[];
  title?: string;
  ogImage?: string;
};

export const Seo: FC<SeoProps> = ({ title, description, lang = "en", meta = [], ogImage }) => {
  const { site } = useStaticQuery<Queries.SeoQuery>(
    graphql`
      query Seo {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  );

  const metaDescription = description || site?.siteMetadata?.description || undefined;
  const defaultTitle = site?.siteMetadata?.title;
  const defaultMetas: MetaProps[] = [
    {
      name: `description`,
      content: metaDescription,
    },
    {
      property: `og:title`,
      content: title,
    },
    {
      property: `og:description`,
      content: metaDescription,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:title`,
      content: title,
    },
    {
      name: `twitter:description`,
      content: metaDescription,
    },
  ];

  if (ogImage) {
    defaultMetas.push({
      key: "og:image",
      property: "og:image",
      content: ogImage,
    });
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : undefined}
      meta={defaultMetas.concat(meta)}
    />
  );
};
