exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-projects-tsx": () => import("./../../../src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-1-initial-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=G:/source/repos/lloreggia-ch/content/blog/1-initial/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-1-initial-index-mdx" */),
  "component---src-templates-page-tsx-content-file-path-content-pages-about-index-mdx": () => import("./../../../src/templates/page.tsx?__contentFilePath=G:/source/repos/lloreggia-ch/content/pages/about/index.mdx" /* webpackChunkName: "component---src-templates-page-tsx-content-file-path-content-pages-about-index-mdx" */),
  "component---src-templates-project-tsx-content-file-path-content-projects-dingleberry-index-mdx": () => import("./../../../src/templates/project.tsx?__contentFilePath=G:/source/repos/lloreggia-ch/content/projects/dingleberry/index.mdx" /* webpackChunkName: "component---src-templates-project-tsx-content-file-path-content-projects-dingleberry-index-mdx" */),
  "component---src-templates-project-tsx-content-file-path-content-projects-micser-index-mdx": () => import("./../../../src/templates/project.tsx?__contentFilePath=G:/source/repos/lloreggia-ch/content/projects/micser/index.mdx" /* webpackChunkName: "component---src-templates-project-tsx-content-file-path-content-projects-micser-index-mdx" */),
  "component---src-templates-project-tsx-content-file-path-content-projects-music-index-mdx": () => import("./../../../src/templates/project.tsx?__contentFilePath=G:/source/repos/lloreggia-ch/content/projects/music/index.mdx" /* webpackChunkName: "component---src-templates-project-tsx-content-file-path-content-projects-music-index-mdx" */)
}

