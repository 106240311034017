import React, { FC } from "react";
import { Card, CardBody, CardFooter, CardHeader, CardProps, LinkBox, LinkOverlay, Text, chakra, useColorModeValue } from "@chakra-ui/react";
import { GatsbyImage, IGatsbyImageData, ImageDataLike, getImage } from "gatsby-plugin-image";

import { Link } from "../atoms";

export type LinkCardProps = Omit<CardProps, "title"> & {
  to: string;
  title?: React.ReactNode | null;
  footer?: React.ReactNode | null;
  image?: ImageDataLike | null;
};

const Image = chakra(GatsbyImage);

export const LinkCard: FC<LinkCardProps> = ({ to, title, image, footer, children, ...rest }) => {
  const bg = useColorModeValue("gray.50", "gray.700");
  const hoverBg = useColorModeValue("gray.100", "gray.600");
  const imageData = image && getImage(image);
  return (
    <LinkBox as={Card} transition="all 0.5s" background={bg} _hover={{ background: hoverBg }} {...rest}>
      {imageData && <Image image={imageData} alt="" objectFit="cover" width="full" height="150px" borderTopRadius="var(--card-radius)" />}
      {(title || imageData) && (
        <CardHeader fontSize="large">
          <LinkOverlay as={Link} to={to} _hover={{ textDecoration: "none" }}>
            {title}
          </LinkOverlay>
        </CardHeader>
      )}
      <CardBody>{children}</CardBody>
      {footer && <CardFooter>{footer}</CardFooter>}
    </LinkBox>
  );
};
